<template>
  <div class="flex flex-1 flex-col border-b border-grid px-5 py-2">
    <div class="flex flex-1 flex-col p-2">
      <div class="flex items-center justify-between">
        <div class="text-xs font-semibold text-gray-100">Instructions</div>
        <BaseToggle class="ml-auto mr-2" v-model="personal" @update:model-value="page = 1">
          <template #left>Private Channel</template>
          <template #right>Personal Chat</template>
        </BaseToggle>
      </div>
      <template v-if="personal">
        <div
          class="mt-3 flex flex-1 flex-col gap-2 pr-2"
          v-for="(instruction, index) in personalInstructions"
          :key="instruction"
          v-show="index + 1 == page"
        >
          <div class="h-8 text-xs font-medium text-gray-100">{{ page }}. <span v-html="instruction" /></div>
          <div class="mt-2 flex h-64 w-full items-center justify-center">
            <a
              target="_blank"
              :href="`https://the-tie-sigdev.s3.us-east-2.amazonaws.com/alert_instructions/telegram_personal_${page}.png`"
            >
              <img
                class="m-auto h-auto max-h-64 w-auto max-w-md rounded"
                :src="`https://the-tie-sigdev.s3.us-east-2.amazonaws.com/alert_instructions/telegram_personal_${page}.png`"
              />
            </a>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="mt-3 flex flex-1 flex-col gap-2 pr-2 text-xs"
          v-for="(instruction, index) in privateInstructions"
          :key="instruction"
          v-show="index + 1 == page"
        >
          <div class="h-8 text-xs font-medium text-gray-100">{{ page }}. <span v-html="instruction" /></div>
          <div class="flex h-64 w-full items-center justify-center">
            <a
              v-if="page != 5"
              target="_blank"
              :href="`https://the-tie-sigdev.s3.us-east-2.amazonaws.com/alert_instructions/telegram_private_channel_${page}.png`"
            >
              <img
                class="max-w-3/4 m-auto h-auto max-h-64 w-auto rounded"
                :src="`https://the-tie-sigdev.s3.us-east-2.amazonaws.com/alert_instructions/telegram_private_channel_${page}.png`"
              />
            </a>
          </div>
        </div>
      </template>
    </div>
    <BasePaginate
      v-model="page"
      :pages="personal ? personalInstructions.length : privateInstructions.length"
      class="mx-auto mb-4 mt-10 w-96"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';

const page = ref(1);
const personal = ref(true);

const personalInstructions = ref([
  `Search for
            <p class="inline-block rounded bg-gray-700 px-1">@the_tie_bot</p>
            in Telegram and hit start.`,
  `Search for <span class="rounded bg-gray-700 px-1">@userinfobot</span> and send a message, which will
            return your ID number. Copy this ID number and paste it into the
            <span class="rounded bg-gray-700 px-1">Chat ID</span> field below.`
]);

const privateInstructions = ref([
  `Create a new channel in Telegram.`,
  `Make the channel private.`,
  `Add <span class="rounded bg-gray-700 px-1">@the_tie_bot</span> and
          <span class="rounded bg-gray-700 px-1">@username_to_id_bot</span> to your chat by going to the channel
          settings and adding them as Admins.`,
  `Send a message in your channel. Forward the message you sent in the channel to <span class="rounded bg-gray-700 px-1">@username_to_id_bot</span>.
            This will return your Channel ID.`,
  `Copy this number and paste it into the <span class="rounded bg-gray-700 px-1">Chat ID</span> field below. You can now remove the
            <span class="rounded bg-gray-700 px-1">@username_to_id_bot</span> from your channel`
]);
</script>
